import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "src/app/app.service";
import { AuthService } from "src/app/services/auth.service";

import { HostService } from "src/app/services/host.service";


@Component({
  selector: "app-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
})
export class UserMenuComponent implements OnInit {
  mailSnippets: string[] = [];
  public userDetails: any;
  show : boolean;
  check:any;
  blockMenuUser: boolean;

  src: any;
  UserImage :any;
  userImage :any;
  elementImage: boolean;
  test : any;
  constructor(public appService: AppService, 
              private getHost: HostService, 
              public router: Router,
              public authService: AuthService) {}
  ngOnInit() {

    this.src = this.getHost.getHttpsImage();
    console.log(this.authService.isLoggedIn, this.blockMenuUser)
    this.getInfoOfUser();
    

  }

  ngDoCheck(): void {

    this.getInfoOfUser();
    
  }

  getInfoOfUser() {

    if ((this.authService.isLoggedIn) && (this.blockMenuUser = true)){

      const storageAuth = localStorage.getItem("user");

      if(storageAuth) {

        this.userDetails = JSON.parse(storageAuth!);

        if (this.userDetails.user_image == null) {

          this.elementImage = false

          this.UserImage = this.src+'images/avatars/avatar-2.png'

        }else {

          this.UserImage = this.userDetails.user_image;

          this.elementImage = true

        }

      } else {
        
        this.blockMenuUser = false

      }
      

    }else {

      this.blockMenuUser = false

    }

  }
  
  logout(): void {

    this.authService.logout();

  }
}
