import { DataSearchService } from 'src/app/services/data-search.service';
import { Router } from '@angular/router';
import { HostService } from './../../services/host.service';
import { AppSettings, Settings } from 'src/app/app.settings';
import { Component, Input, OnInit } from '@angular/core';
import { VehiculeserviceService } from 'src/app/services/vehiculeservice.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-map-senegal',
  templateUrl: './map-senegal.component.html',
  styleUrls: ['./map-senegal.component.scss']
})
export class MapSenegalComponent implements OnInit {

    //Subscription
    vehicleSubscription: Subscription

    @Input('mapHome') mapHome:boolean = true;
    @Input('mapLatestLocation') mapLatestLocation: boolean = false;
    @Input('geometry') geometry: any;
    @Input('markers') markers: any[] = [];

    public lat: number;
    public lng: number;
    public zoom: number; 

    public mapStyles:any = [
            {
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": 36
                    },
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#8b9198"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#323336"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#414954"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#2e2f31"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#7a7c80"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#242427"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#202022"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#393a3f"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#202022"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#393a3f"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#202022"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#202124"
                    }
                ]
            }
    ];

    public settings: Settings;

    public markersHome: any;

    public icon: any;

    constructor(private appSettings:AppSettings,
                private hostService: HostService,
                private router: Router,
                private dataSearchService: DataSearchService,
                private vehicleService: VehiculeserviceService) { 

        this.settings = this.appSettings.settings; 

    }



    ngOnInit(): void {

        this.initProperty();

    }

    initProperty() {

        this.lat = 14.497401;
        this.lng = -14.452362;
        this.zoom = 9; 

        this.icon = 'assets/images/logo/icon_marker.png';
        
        //Ici nous recuperons les markers pour la carte se trouvant sur la première page
        this.vehicleSubscription = this.vehicleService.vehicleMap().subscribe((marks: any) => {

            this.markersHome = marks.markers;

            // console.log(this.markersHome, marks)

        })
    }

   
    
    ngDoCheck(): void {

        if(this.geometry != undefined || this.geometry != null ) {

            this.lat = this.geometry.location.lat();
            this.lng = this.geometry.location.lng();
            this.zoom = 14;

            // this.zoom= 13; Afficher bien dakar senegal

        }
       
        if(!this.mapHome) {

            if(this.dataSearchService.isRentingMap) {

                this.lat = this.dataSearchService.lat;
                this.lng = this.dataSearchService.lng;

                this.markers = this.markersHome;

            } else {

                this.lat = this.dataSearchService.geometry.location.lat();
                this.lng = this.dataSearchService.geometry.location.lng();

                this.dataSearchService.isRentingMap = false;

            }
           
            this.zoom = 14;

        }
        
    }

    //Lorsque on clique sur un marker que j'ai eut a crée
    selectMarker(event: any) {

        this.dataSearchService.idCar = event.label.id;

        this.router.navigate(['location-car']);
        
    }

    /**
     * Lorsqu'il clique sur un marker se trouvant sur la première page de l'application
     * @param event 
     */
    selectMarkerMapHome(event: any) {

        this.dataSearchService.idCar = event.label.id;

        let currentMarker =  this.markersHome.find((element: any) => element.id == event.label.id)

        this.dataSearchService.address = currentMarker.address;

        this.dataSearchService.lat = currentMarker.lat

        this.dataSearchService.lng = currentMarker.lng

        this.dataSearchService.isRentingMap = true;

        this.router.navigate(['location-car']);

    }
}
