<footer>
    <div class="px-3">
        <div class="theme-container">

            <div class="py-5 content border-lighter">
                <div fxLayout="row wrap" fxLayoutAlign="space-between">
                    <div fxFlex="100" fxFlex.gt-sm="50" fxFlex.sm="45">
                        <a class="logo" routerLink="/">
                            <app-logo [size]="true"></app-logo>
                            <p >
                           
                                Location Auto Senegal est une plateforme sécurisée de location de voitures 
                                et d’utilitaires basée sur la  consommation collaborative.
                                Le concept propose un usage plus simple et plus flexible de la voiture avec
                                la possibilité pour l’utilisateur locataire de louer un véhicule  en 
                                quelques clics, à chaque instant, partout au Sénégal.
                                Société enregistrée au R.C sous le numéro <strong> DKR 2020 B 14769 
                           </strong> le 13/08/2020.
                            </p>
                        </a>

                    </div>

                    <div fxFlex="100" fxFlex.gt-sm="25" fxFlex.sm="45">
                        <h1 class="secondary-font">Locataires</h1>
                        <br/>
                        <p>
                            <br/><a  routerLink="/">Astuces</a>
                            <br/><a  routerLink="/">Assurance</a>
                            <br/><a  routerLink="/">Experience location</a>
                        </p>
                    </div>

                    <div fxFlex="100" fxFlex.gt-sm="25" fxFlex.sm="45">
                        <h1 class="secondary-font">Proprietaire</h1>
                        <br/>
                        <p>
                            <br/><a  routerLink="/">Professionnels</a>
                            <br/><a  routerLink="/">Astuces</a>
                            <br/><a  routerLink="/">Experience location</a>
                            <br/><a  routerLink="/">Assurance</a>
                        </p>
                    </div>

                </div>
            </div>




            <div class="py-5 content border-lighter">
                <div fxLayout="row wrap" fxLayoutAlign="space-between">
                    <div fxFlex="100" fxFlex.gt-sm="25" fxFlex.sm="45">
                        <h1 class="secondary-font">RENTBYAUTO</h1>
                        <img alt="profile image" src="assets/images/categories/trait.jpg" width="50" >
                        <p>
                            <br/><a  routerLink="/">Professionnels</a>
                            <br/><a  routerLink="/">A propos</a>
                            <br/><a  routerLink="/">Règlements</a>
                            <br/><a  routerLink="/">Actualités</a>
                            <br/><a  routerLink="/">Blog</a>
                            <br/><a  routerLink="/">Témoignages</a>
                        </p>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="25" fxFlex.sm="45">
                        <h1 class="secondary-font">Liens utiles</h1>
                        <img alt="profile image" src="assets/images/categories/trait.jpg" width="50" >
                        <p >
                                <br/><a  routerLink="/create-utilisateurs">S'inscrire</a>
                                <br/><a  routerLink="/login">Se connecter</a>
                                <br/><a  routerLink="/reset">Mot de passe perdu</a>
                                <br/><a routerLink="/help/">Proprietaire , comment ça marche?</a>
                                <br/><a routerLink="/help/">Locataire, comment ça marche?</a>
                                <br/><a  routerLink="/help" >Assurance</a>
                                <br/><a  routerLink="/help/les-options-dassurance-et-dassistance-complementaires/">Aides</a>
                                <br/><a routerLink="/help/les-conditions-generales-dutilisation/">CGU</a>
                                <br/><a routerLink="/help/a-propos/">Paiements</a>
                                <br/><a routerLink="/help/nous-ecrire/">Partenaires</a>
                  
                        </p>
                    </div>

                    <div fxFlex="100" fxFlex.gt-sm="25" fxFlex.sm="45" ngClass.xs="pt-5">
                        <h1 class="secondary-font">Decouvrir</h1>
                        <img alt="profile image" src="assets/images/categories/trait.jpg" width="50" >
                        <p>
                            <br/><a  routerLink="/">Louez une voiture</a>
                            <br/><a  routerLink="/">Reserver une voiture</a>
                            <br/><a  routerLink="/">Entreprise</a>
                            <br/><a  routerLink="/">Appli mobile</a>
                            <br/><a  routerLink="/">Assistance depannage</a>
                            <br/><a  routerLink="/">Imprimer ma facture</a>
                        </p>
                    </div>
                    <div fxFlex="100" fxFlex.gt-sm="25" fxFlex.sm="45">
                        <h1 class="secondary-font "  ><a outerLink="/about">Partenaires</a></h1>
                         
                        </div>
                </div>
                <h1 class="secondary-font">Paiements Securisés</h1>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright py-2">
                
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                    <mat-icon color="primary">location_on</mat-icon>
                    <span class="mx-2">Arafat 2 villa N°794, Rufisque, Sénégal</span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <mat-icon color="primary">call</mat-icon>
                    <span class="mx-2">(+221) 77 516 89 18</span>
                </p>

                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <mat-icon color="primary">mail_outline</mat-icon>
                    <span class="mx-2">contact@galsenauto.com</span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <mat-icon color="primary">schedule</mat-icon>
                    <span class="mx-2">Lun - Dim / 8H30 - 12H30 & 13H30 - 18H30</span>
                </p>

                <app-social-icons [iconSize]="'lg'" fxLayout="row" fxLayoutAlign="start center" class="desc"></app-social-icons>
            
            </div>
            

            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright py-2">
               
               
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                    <span class="mx-2">CGU</span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2">CPU</span>
                </p>

                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2">Mentions Légales </span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2">Politique de Confidentialité </span>
                </p>
                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                    <span class="mx-2">  Charte sur les Cookies</span>
                </p>
                <!-- <p ngClass.xs="mt-1">Copyright © {{year}} All Rights Reserved</p> -->
                <p>Copyright © {{year}} All Rights Reserved<a mat-button href="https://www.jainliconsulting.com/" target="_blank">Jainli Consulting </a></p>
            </div>
        </div>
    </div>
</footer>