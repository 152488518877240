<h1 mat-dialog-title class="header-dialog">Prix total</h1>
<div mat-dialog-content>
    <div class="container">
        <div class="body-slider">
            <div *ngIf="value === 0">
                Tous les prix
            </div>
            <div *ngIf="value != 0">
                {{text}}
            </div>
            <div class="content-slider">
                <mat-slider class="slider" [color]="color" (change)="sliderChange('plus', $event)" min="0" max="10000" aria-label="units" value="{{slider1}}"></mat-slider>
                <mat-slider class="slider" [color]="color" (change)="sliderChange('moins', $event)" min="0" max="10000" aria-label="units" value="{{slider2}}"></mat-slider>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button class="search-button-disabled" *ngIf="value === 0">Rechercher</button>
    <button class="search-button" *ngIf="value != 0" (click)="submitButton()">Rechercher</button>

</div>