<mat-card class="menu-item p-0" [fxLayout]="(viewType == 'grid') ? 'column' : 'row'"
    [ngClass]="[viewType + '-item', 'column-'+column]">
    <div fxFlex="100" [fxShrink]="(viewType == 'grid') ? '3' : '2'" class="thumbnail-section">
        <mat-chip-list *ngIf="menuItem.discount">
            <mat-chip color="warn" selected="true">{{menuItem.discount}}% OFF</mat-chip>
        </mat-chip-list>
        <div *ngIf="lazyLoad">
            <!-- <img src={{hostService.getHttpsImage()+menuItem.title_image}} class="w-100 d-block swiper-lazy" height="300"> -->
            <img src={{hostService.getHttpsImage()+menuItem.title_image}} class="vehicule_2 wiper-lazy">
            <div class="swiper-lazy-preloader"></div>
        </div>
        <!-- <img *ngIf="!lazyLoad" src={{hostService.getHttpsImage()+menuItem.title_image}} class="w-100 d-block" height="300"> -->
        <img *ngIf="!lazyLoad" src={{hostService.getHttpsImage()+menuItem.title_image}} class="vehicule_2" >
    </div>
    <div fxFlex="100" class="p-3" (click)="clickCar(menuItem.id)">
        <div fxLayout="column" fxLayoutAlign="start stretch" class="h-100">
            <mat-card-content class="mb-0">
                <h1 class="title">{{menuItem.mark_lib}}</h1>
                <div class="mt-2 mb-2"> 
                <mat-accordion>
                    <mat-expansion-panel class="mat-expansion">
                        <mat-expansion-panel-header>
                            <mat-panel-title fxLayout="row" fxLayoutAlign="space-between">
                                <div class="fw-500">Description</div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <p>{{menuItem.vehicle_description}} </p>
                    </mat-expansion-panel>
                </mat-accordion>
                </div>
            </mat-card-content>
            <div class="sticky-footer-helper" fxFlex="grow"></div>
            <mat-card-actions class="m-0 p-0">
                <div class="features">
                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                        <mat-icon color="primary" class="mat-icon-sm">local_offer</mat-icon>
                        <span class="px-1">Modèle:</span>
                        <span class="dots"></span>
                        <span class="fw-500">{{ menuItem.mod_lib }}</span>
                    </p>
                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                        <mat-icon color="primary" class="mat-icon-sm">local_offer</mat-icon>
                        <span class="px-1">Categorie:</span>
                        <span class="dots"></span>
                        <span class="fw-500">{{ menuItem.vehicle_categorie }}</span>
                    </p>
                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                        <mat-icon color="primary" class="mat-icon-sm">{{ (menuItem.availibilityCount > 0) ?
                            'check_circle' : 'unpublished'}}</mat-icon>
                        <span class="px-1">Disponibilité:</span>
                        <span class="dots"></span>
                        <span class="fw-500">{{ menuItem.status }}</span>
                    </p>
                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                        <mat-icon color="primary" class="mat-icon-sm">local_offer</mat-icon>
                        <span class="px-1">Ville:</span>
                        <span class="dots"></span>
                        <span class="fw-500">{{ menuItem.vehicle_adress}}</span>
                    </p> 
                    <p fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                        <mat-icon color="primary" class="mat-icon-sm">local_offer</mat-icon>
                        <span class="px-1">Nbre places:</span>
                        <span class="dots"></span>
                        <span class="fw-500">{{ menuItem.number_place_limit}}</span>
                    </p>
                </div>

                <div fxLayout="row" [fxLayoutAlign]="(viewType == 'grid') ? 'space-between center' : 'end center'"
                    class="mt-3">
                    <span class="text-muted">Avis Client<span *ngIf="viewType == 'list'" class="px-2">: {{menuItem.note}}</span></span>
                    <app-rating [ratingsCount]='menuItem.note' ratingsValue=2 [comment]='menuItem.count_note'></app-rating>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="divider mt-1">

                    <h2 class="primary-color">
                        {{ menuItem.vehicle_price_location}} {{appService.getCurrency()}}
                    </h2>

                    <div class="text-muted">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="divider mt-1">
                            <a (click)="clickCar(menuItem.id)" mat-raised-button color="primary" class="transform-none"
                                id="btnreserver">Reserver</a>

                        </div>
                    </div>
                </div>
            </mat-card-actions>
        </div>
    </div>
</mat-card>
