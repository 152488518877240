<!-- <div class="eZQodN">
    <div class="cVlGDf">
        <div class="cthvpR">
            <button class="jZjSnZ">
                <strong class="egFvSS iBCUHQ">Prix</strong>
                <svg color="#007E78" style="display:inline-block;stroke:currentColor;fill:currentColor;width:20px;height:20px" viewBox="0 0 1024 1024">
                    <path d="M778.712 376.426c14.281-14.282 37.438-14.282 51.719 0l182.857 182.857c14.281 14.281 14.281 37.438 
                    0 51.719s-37.438 14.281-51.719 0l-156.997-156.997-156.997 156.997c-14.281 14.281-37.438 14.281-51.719 
                    0s-14.281-37.438 0-51.719l182.857-182.857z"></path>
                </svg>
                <svg color="#007E78" style="display:inline-block;stroke:currentColor;fill:currentColor;width:20px;height:20px" viewBox="0 0 1024 1024">
                    <path d="M778.712 376.426c14.281-14.282 37.438-14.282 51.719 0l182.857 182.857c14.281 14.281 14.281 37.438 0 51.719s-37.438 14.281-51.719 
                    0l-156.997-156.997-156.997 156.997c-14.281 14.281-37.438 14.281-51.719 0s-14.281-37.438 0-51.719l182.857-182.857z"></path>
                </svg>
            </button>
        </div>
        <div class="iBPDIf">
            <h1 class="text__TextElement-sc-1oa881f-0 duoHcD">
                <span itemprop="name"> Location voiture entre particuliers: </span>
            </h1>
            <p class="text__TextElement-sc-1oa881f-0 duoHcD car-number__StyledText-sc-ey9tyf-0 flHDjZ">Plus de 1000 résultats.</p>
        </div>
    </div>
</div> -->
<div class="filter">
    <div class="content-filter table-wrapper">
        <div class="item">
            <div>
                <button *ngIf="!priceSelect" class="button-search" (click)="openModalPrice()">
                    prix
                    <svg  color="#1565c0" style="display:inline-block;stroke:currentColor;fill:currentColor;width:20px;height:20px" viewBox="0 0 1024 1024"><path d="M595.855 376.426c14.281-14.282 37.438-14.282 51.719 0l156.997 156.997 156.997-156.997c14.281-14.282 37.438-14.282 51.719 0 14.281 14.281 14.281 37.438 0 51.719l-182.857 182.857c-14.281 14.281-37.438 14.281-51.719 
                        0l-182.857-182.857c-14.281-14.281-14.281-37.438 0-51.719z"></path>
                    </svg>
                </button>
                <button *ngIf="priceSelect" class="button-search-select" (click)="openModalPrice()">
                    prix
                    <svg color="#1565c0" style="display:inline-block;stroke:currentColor;fill:currentColor;width:20px;height:20px" viewBox="0 0 1024 1024">
                        <path d="M778.712 376.426c14.281-14.282 37.438-14.282 51.719 0l182.857 182.857c14.281 14.281 14.281 37.438 0 51.719s-37.438 14.281-51.719 
                            0l-156.997-156.997-156.997 156.997c-14.281 14.281-37.438 14.281-51.719 0s-14.281-37.438 0-51.719l182.857-182.857z"></path>
                    </svg>
                </button>
            </div>
            <div>
                <button *ngIf="!typeSelect" class="button-search" (click)="openModalType()">
                    Type de véhicule
                    <svg color="#1565c0" style="display:inline-block;stroke:currentColor;fill:currentColor;width:20px;height:20px" viewBox="0 0 1024 1024"><path d="M595.855 376.426c14.281-14.282 37.438-14.282 51.719 0l156.997 156.997 156.997-156.997c14.281-14.282 37.438-14.282 51.719 0 14.281 14.281 14.281 37.438 0 51.719l-182.857 182.857c-14.281 14.281-37.438 14.281-51.719 
                        0l-182.857-182.857c-14.281-14.281-14.281-37.438 0-51.719z"></path>
                    </svg>
                </button>
                <button *ngIf="typeSelect" class="button-search-select" (click)="openModalType()">
                    Type de véhicule
                    <svg color="#1565c0" style="display:inline-block;stroke:currentColor;fill:currentColor;width:20px;height:20px" viewBox="0 0 1024 1024">
                        <path d="M778.712 376.426c14.281-14.282 37.438-14.282 51.719 0l182.857 182.857c14.281 14.281 14.281 37.438 0 51.719s-37.438 14.281-51.719 
                            0l-156.997-156.997-156.997 156.997c-14.281 14.281-37.438 14.281-51.719 0s-14.281-37.438 0-51.719l182.857-182.857z"></path>
                    </svg>
                </button>
            </div>
            <div>
                <button *ngIf="!moreFilterSelect" class="button-search" (click)="openModalMoreFilter()">
                    Plus de filtes
                    <svg color="#1565c0" style="display:inline-block;stroke:currentColor;fill:currentColor;width:20px;height:20px" viewBox="0 0 1024 1024"><path d="M595.855 376.426c14.281-14.282 37.438-14.282 51.719 0l156.997 156.997 156.997-156.997c14.281-14.282 37.438-14.282 51.719 0 14.281 14.281 14.281 37.438 0 51.719l-182.857 182.857c-14.281 14.281-37.438 14.281-51.719 
                        0l-182.857-182.857c-14.281-14.281-14.281-37.438 0-51.719z"></path>
                    </svg>
                </button>
                <button *ngIf="moreFilterSelect" class="button-search-select" (click)="openModalMoreFilter()">
                    Plus de filtes
                    <svg color="#1565c0" style="display:inline-block;stroke:currentColor;fill:currentColor;width:20px;height:20px" viewBox="0 0 1024 1024">
                        <path d="M778.712 376.426c14.281-14.282 37.438-14.282 51.719 0l182.857 182.857c14.281 14.281 14.281 37.438 0 51.719s-37.438 14.281-51.719 
                            0l-156.997-156.997-156.997 156.997c-14.281 14.281-37.438 14.281-51.719 0s-14.281-37.438 0-51.719l182.857-182.857z"></path>
                    </svg>
                </button>
            </div>
            <button class="reset button-reset" *ngIf="showButtonReset" (click)="resetFliter()">
                <span>
                    <div class="span-reset">Réinitialiser</div>
                </span>
            </button>
        </div>
        <div class="result-search">
            <p>Location voiture entre particuliers: {{numberOfResult}} résultats trouvés</p>
        </div>
    </div>
</div>


<!-- <div class="filter" fxLayout="row wrap" fxLayoutAlign="center" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0">
    <div fxFlex fxFlexOffset="50px" fxFlexOffset.xs="0">
        <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.xs="0">
            <div fxFlex="100" fxFlex.gt-sm="8">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Prix</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="100" fxFlex.gt-sm="25">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Réservation instantannée</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-dialog-close class="button-search">
                    Rechercher
                    <svg color="#1565c0" style="display:inline-block;stroke:currentColor;fill:currentColor;width:20px;height:20px" viewBox="0 0 1024 1024"><path d="M595.855 376.426c14.281-14.282 37.438-14.282 51.719 0l156.997 156.997 156.997-156.997c14.281-14.282 37.438-14.282 51.719 0 14.281 14.281 14.281 37.438 0 51.719l-182.857 182.857c-14.281 14.281-37.438 14.281-51.719 
                        0l-182.857-182.857c-14.281-14.281-14.281-37.438 0-51.719z">
                    </path>
                </svg>
                </button>

                <div class="cthvpR">
                    <button class="iUQkhv">
                        <strong class="egFvSS iBCUHQ">Réservation instantannée</strong>
                        <svg color="#007E78" style="display:inline-block;stroke:currentColor;fill:currentColor;width:20px;height:20px" viewBox="0 0 1024 1024"><path d="M595.855 376.426c14.281-14.282 37.438-14.282 51.719 0l156.997 156.997 156.997-156.997c14.281-14.282 37.438-14.282 51.719 0 14.281 14.281 14.281 37.438 0 51.719l-182.857 182.857c-14.281 14.281-37.438 14.281-51.719 0l-182.857-182.857c-14.281-14.281-14.281-37.438 0-51.719z"></path></svg>
                    </button>
                </div>
            </div>
            <div fxFlex="100" fxFlex.gt-sm="18">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Type de véhicule</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="100" fxFlex.gt-sm="15">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Plus de filtes</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div fxFlex="100" fxFlex.gt-sm="400px">
        <p class="result-search">Location voiture entre particuliers: 237 résultats trouvés</p>
    </div>
</div> -->