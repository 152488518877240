import { FormArray, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, AfterViewInit, Inject } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-modal-type',
  templateUrl: './modal-type.component.html',
  styleUrls: ['./modal-type.component.scss']
})
export class ModalTypeComponent implements OnInit, AfterViewInit {

  color: any = 'primary';
  
  form: FormGroup;

  public typeCars: any;

  showButton: boolean;

  public checkoutSelect: false;

  constructor(private breakpointObserver: BreakpointObserver, 
              private dialogRef: MatDialogRef<ModalTypeComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private appService: AppService) {  

      this.form = this.fb.group({
        checkArray: this.fb.array([]),
      });
  }

  ngOnInit(): void {

    this.initProperty();

  }

  private initProperty() {

    this.showButton = false;

    this.typeCars = this.data['tabTypes'];

    console.log(this.typeCars);

    if(this.data['formType'] != undefined) {
      
      const valueType: FormArray = new FormArray([...this.data['formType'].map((item: any) => new FormControl(item))]
      );

      this.form.setControl('checkArray', valueType);

      //On verifier si la taille du tableau renfermant les types de véhicules est > à Zero
      if(this.data['formType'].length > 0) {

        this.showButton = true;

      }
      
    }

  }

  //Ici nous gérons la disposition du modal
  ngAfterViewInit() {
    this.detectScreenSize();
  }

  @HostListener("window:resize", [])
  onResize() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    
    this.breakpointObserver.observe(["(max-width:480px)"]).subscribe((result: BreakpointState) => {
       
        if(result.matches) {
          // console.log('je suis dans 480 if')
          //Pour les phones
          this.dialogRef.updatePosition({left: '20px', top: '200px'})
  
        } else {

          this.breakpointObserver.observe(["(max-width:768px)"]).subscribe((resultTwo: BreakpointState) => {

            if(resultTwo.matches) {

              // console.log('je suis dans 768 if')

              this.dialogRef.updatePosition({left: '20px', top: '200px'});
              
            } else {

              // console.log('je suis dans 768 else')
              //Pour les desktop
              this.breakpointObserver.observe(["(max-width:1600px)"]).subscribe((resultThree: BreakpointState) => {

                if(resultThree.matches) {

                  // console.log('je suis dans 1600 if')

                  this.dialogRef.updatePosition({left: '290px', top: '55px'});

                } else {
                  // console.log('je suis dans 1600 else')
                  this.dialogRef.updatePosition({top: '185px', left: '120px'})

                }
              })
              
            }
          })
  
        }
  
    })
  }

  //Gestion des clics
  @HostListener('document:click')
    clicked() {

    this.dialogRef.backdropClick().subscribe((res:any) => {
    
      this.dialogRef.close({type: this.form.get('checkArray')?.value, typeFilter: 'type', click: false});

    })

  }
  
  onCheckboxChange(e: any) {

    let currentType = this.typeCars.find((type: any) => type.code.trim().toLowerCase() == e.source.value.trim().toLowerCase());

    // let index = this.typeCars.map((object: any) => object.code).indexOf(e.source.value);

    currentType.completed = e.checked;

    const checkArray: FormArray = this.form.get('checkArray') as FormArray;

    if(e.checked) {

      checkArray.push(new FormControl(e.source.value));

    } else {

      let i: number = 0;

      checkArray.controls.forEach((item: any) => {

        if(item.value == e.source.value) {

          checkArray.removeAt(i);

          return ;

        }

        i++
      })
    }

    if(checkArray.value.length <= 0) {
      
      this.showButton = false;

    } else {
      
      this.showButton = true;

    }

    // console.log(this.typeCars, this.appService.getCategorieVehicule())

  }

  submitButton() {

    this.dialogRef.close({type: this.form.get('checkArray')?.value, typeFilter: 'type', click: true});

  }
  

}
