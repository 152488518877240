import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { emailValidator } from '../../utils/app-validators';
import { UserServiceService } from 'src/app/services/user-service.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    pdfsources = "../assets/pdf/documentation.pdf"; 
    public feedbackForm!: UntypedFormGroup;
    public subscribeForm!: UntypedFormGroup;
    
    maDate = new Date();
    year: any;

    constructor(public formBuilder: UntypedFormBuilder, private datePipe: DatePipe,
                public service:UserServiceService, private toastr: ToastrService,
                private spinner: NgxSpinnerService) { }

    ngOnInit() {
        this.feedbackForm = this.formBuilder.group({ 
            email: ['', Validators.compose([Validators.required, emailValidator])], 
            message: ['', Validators.required]
        });
        this.subscribeForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, emailValidator])]
        })

        this.year = this.datePipe.transform(this.maDate, 'yyyy');
        
    }

    public onFeedbackFormSubmit(values:Object):void {
        if (this.feedbackForm.valid) {
            
            this.spinner.show();

            this.service.feedBack(values).subscribe((response: any) => {

                if(response.status) {

                    this.toastr.success('Votre commentaire a été envoyé avec succès ');
   
                    this.spinner.hide();

                } else {

                    this.toastr.error('Une erreur est survenue lors de l\'envoi du message. Veuillez réessayer ultérieurement');

                }

                this.feedbackForm.reset();
            })
        }
    }

    public onSubscribeFormSubmit(values:Object):void {
        if (this.subscribeForm.valid) {
            console.log(values);
        }
    }

}
