<h1 mat-dialog-title class="header-dialog">Details de la location</h1>
<div mat-dialog-content>
    <div class="container-first">
        <ul>
            <li>
                <div class="text-content-one">
                    <span class="detail-text">Prix {{dataPrice.diffWeek | number: '1.0-0'}} jours</span>
                </div>
                <div>
                    <span class="detail-text-two">{{dataPrice.priceCar}} {{currency}}</span>
                </div>
            </li>
            <!-- <li>
                <div>
                    <span class="detail-text-supp">Supplément jeune conducteur</span>
                    <span class="span-icon">
                        <svg color="#AFB0B2" viewBox="0 0 1024 1024" style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 17px; height: 17px;">
                            <path d="M512 164.571c-191.88 0-347.429 155.549-347.429 347.429s155.549 347.429 347.429 347.429c191.879 0 347.429-155.549 
                            347.429-347.429s-155.549-347.429-347.429-347.429zM91.429 512c0-232.275 188.296-420.571 420.571-420.571 232.276 0 420.571 
                            188.296 420.571 420.571 0 232.276-188.295 420.571-420.571 420.571-232.275 0-420.571-188.295-420.571-420.571zM475.429 341.333c0-20.198 
                            16.373-36.571 36.571-36.571h0.428c20.198 0 36.571 16.374 36.571 36.571s-16.373 36.57-36.571 36.57h-0.428c-20.198 0-36.571-16.372-36.571-36.57zM469.339 
                            475.429c-20.198 0-36.571 16.373-36.571 36.571s16.373 36.571 36.571 36.571h6.096v134.096c0 20.198 16.373 36.571 36.571 36.571h42.664c20.198 0 36.571-16.373 
                            36.571-36.571s-16.373-36.571-36.571-36.571h-6.093v-134.096c0-20.198-16.377-36.571-36.571-36.571h-42.668z"></path>
                        </svg>
                    </span>
                </div>
                <div>
                    <p>Non Inclus</p>
                </div>
            </li> -->
            <li>
                <div>
                    <span class="detail-text-supp">Assurance</span>
                    <span class="span-icon">
                        <svg color="#AFB0B2" viewBox="0 0 1024 1024" style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 17px; height: 17px;">
                            <path d="M512 164.571c-191.88 0-347.429 155.549-347.429 347.429s155.549 347.429 347.429 347.429c191.879 0 347.429-155.549 
                            347.429-347.429s-155.549-347.429-347.429-347.429zM91.429 512c0-232.275 188.296-420.571 420.571-420.571 232.276 0 420.571 
                            188.296 420.571 420.571 0 232.276-188.295 420.571-420.571 420.571-232.275 0-420.571-188.295-420.571-420.571zM475.429 341.333c0-20.198 
                            16.373-36.571 36.571-36.571h0.428c20.198 0 36.571 16.374 36.571 36.571s-16.373 36.57-36.571 36.57h-0.428c-20.198 0-36.571-16.372-36.571-36.57zM469.339 
                            475.429c-20.198 0-36.571 16.373-36.571 36.571s16.373 36.571 36.571 36.571h6.096v134.096c0 20.198 16.373 36.571 36.571 36.571h42.664c20.198 0 36.571-16.373 
                            36.571-36.571s-16.373-36.571-36.571-36.571h-6.093v-134.096c0-20.198-16.377-36.571-36.571-36.571h-42.668z"></path>
                        </svg>
                    </span>
                </div>
                <div>
                    <p>
                        <span class="detail-text-two">{{dataPrice.priceInsurance}} {{currency}}</span>
                    </p>
                </div>
            </li>
            <li>
                <div>
                    <span class="detail-text-supp">Service Location Auto Senegal</span>
                    <span class="span-icon">
                        <svg color="#AFB0B2" viewBox="0 0 1024 1024" style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 17px; height: 17px;">
                            <path d="M512 164.571c-191.88 0-347.429 155.549-347.429 347.429s155.549 347.429 347.429 347.429c191.879 0 347.429-155.549 
                            347.429-347.429s-155.549-347.429-347.429-347.429zM91.429 512c0-232.275 188.296-420.571 420.571-420.571 232.276 0 420.571 
                            188.296 420.571 420.571 0 232.276-188.295 420.571-420.571 420.571-232.275 0-420.571-188.295-420.571-420.571zM475.429 341.333c0-20.198 
                            16.373-36.571 36.571-36.571h0.428c20.198 0 36.571 16.374 36.571 36.571s-16.373 36.57-36.571 36.57h-0.428c-20.198 0-36.571-16.372-36.571-36.57zM469.339 
                            475.429c-20.198 0-36.571 16.373-36.571 36.571s16.373 36.571 36.571 36.571h6.096v134.096c0 20.198 16.373 36.571 36.571 36.571h42.664c20.198 0 36.571-16.373 
                            36.571-36.571s-16.373-36.571-36.571-36.571h-6.093v-134.096c0-20.198-16.377-36.571-36.571-36.571h-42.668z"></path>
                        </svg>
                    </span>
                </div>
                <div>
                    <p>
                        <span class="detail-text-two">{{dataPrice.pricePlatform}} {{currency}}</span>
                    </p>
                </div>
            </li>
            <li>
                <div>
                    <span class="total-price">Total</span>
                </div>
                <div>
                    <span class="value-total-price">{{dataPrice.totalPrice}} {{currency}}</span>
                </div>
            </li>
        </ul>
    </div>
    <!-- <p class="detail-text-two caution">Une caution de 400€ sera effectuée 72h avant le début votre location.</p>
    <div class="container-second">
        <strong class="total-price">Informations complémentaires</strong>
        <ul class="ul-info-sup">
            <div class="div-info-sup">
                <div class="div-text-info">
                    <li class="detail-text-supp">Conducteurs supplémentaires</li>
                    <span class="span-icon">
                        <svg color="#AFB0B2" viewBox="0 0 1024 1024" style="display: inline-block; stroke: currentcolor; fill: currentcolor; width: 17px; height: 17px;">
                            <path d="M512 164.571c-191.88 0-347.429 155.549-347.429 347.429s155.549 347.429 347.429 347.429c191.879 0 347.429-155.549 
                            347.429-347.429s-155.549-347.429-347.429-347.429zM91.429 512c0-232.275 188.296-420.571 420.571-420.571 232.276 0 420.571 
                            188.296 420.571 420.571 0 232.276-188.295 420.571-420.571 420.571-232.275 0-420.571-188.295-420.571-420.571zM475.429 341.333c0-20.198 
                            16.373-36.571 36.571-36.571h0.428c20.198 0 36.571 16.374 36.571 36.571s-16.373 36.57-36.571 36.57h-0.428c-20.198 0-36.571-16.372-36.571-36.57zM469.339 
                            475.429c-20.198 0-36.571 16.373-36.571 36.571s16.373 36.571 36.571 36.571h6.096v134.096c0 20.198 16.373 36.571 36.571 36.571h42.664c20.198 0 36.571-16.373 
                            36.571-36.571s-16.373-36.571-36.571-36.571h-6.093v-134.096c0-20.198-16.377-36.571-36.571-36.571h-42.668z"></path>
                        </svg>
                    </span>
                </div>
                <li class="detail-text-supp text-inclus">Inclus</li>
            </div>
            <div class="div-info-sup">
                <div class="div-text-info">
                    <li class="detail-text-supp">Essence</li>
                </div>
                <li class="detail-text-supp text-non-inclus">Non Inclus</li>
            </div>
        </ul>
    </div> -->
</div>