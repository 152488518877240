import { Injectable } from '@angular/core';
import { TemplateSearchCar } from 'src/app/app.models';

@Injectable({
  providedIn: 'root'
})
export class DataSearchService {

  //Search car
  public address: any | null;
  public geometry: any | null;
  public markers: any | null;
  public diffDate: number | null;
  public templateSearchCar: TemplateSearchCar;

  //location car
  public idCar: number;

  //Latest Location Car
  public priceLocation: number;
  public isConnected: boolean = true
  // lat: any;
  // lng: any;
  // isRentingMap: boolean;
  

  //Home Map
  public lat: number;
  public lng: number;
  public isRentingMap: boolean = false;


  //Condition of period search
  public periodCondition(val: string | null): string | null {

    if(val == null) {

      return null;

    } else {

      if(val == 'Après 12h') {

        return 'Ap 12h';
  
      } else {
  
        return 'Av 12h'
      }

    }
    
    
  }
  
}
