<div class="pg-home-bg is-grey w-padding">
    <div class="inside-wide with-padding-rwd">
        <div class="display-flex align-space align-stretch with-rwd">
            <section class="pg-home-bottom is-left"> <br/><br/>
                <h3 class="pg-home-title">Des questions ?</h3>
                <p class="txt fz-18">
                    Notre équipe est à votre disposition tous les jours au
                    <!-- -->&nbsp;
                    <span class="fwb secondarycolor">682 79 24 83</span>.
                </p>
                <p class="fz-14 lightmorecolor mt1">
                    Du lundi au vendredi de 8h30 à 18h30. Samedi, dimanche et jour fériés de 8h30 à 18h30.
                </p>
            </section>
            <section class="pg-home-bottom is-right">
                <h3 class="pg-home-title">Toutes les locations sont assurées par NSIA</h3>
                <div class="display-flex align-space align-top with-rwd">
                    <div class="txt fz-18 mr3">
                        Assurance incluse dans le prix. <br> Couverture multirisque accident et vol.<br> Assistance dépannage 24h/24 pour tous les véhicules.
                    </div>
                    <div class="logos-container">
                        <a routerLink="/ConditionAssurance" > 
                        <img [src]="hostServiceImage+'images/others/assurance.png'" alt="logo assurnace">
                        </a>
                        
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>