<div id="app" class="app" [dir]="(settings.rtl) ? 'rtl' : 'ltr'"
            [ngClass]="[settings.theme, 'toolbar-'+settings.toolbar]" 
            [class.main-toolbar-fixed]="settings.mainToolbarFixed"> 
    <router-outlet></router-outlet>
    <ng-progress [spinner]="false" [meteor]="false" color="#e91e63"></ng-progress>
    <ngx-spinner type="ball-clip-rotate-multiple" color="#e91e63" bdColor="rgba(51,51,51,0.8)">
        <p style="font-size: 20px; color: #e91e63; margin-top: 20px; font-weight: bold;">Loading...</p>
    </ngx-spinner>
 
</div>
