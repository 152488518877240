import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { HostService } from './host.service';
import { MenuItem } from '../app.models';
import { DatePipe } from '@angular/common';
import { Cacheable } from "ts-cacheable"

@Injectable({
  providedIn: "root",
})
export class VehiculeserviceService {
  
  constructor(private http: HttpClient, private getAuth: HostService, private datePipe: DatePipe) {}
  
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      //Une erreur côté client ou réseau s'est produite. Manipulez-le en conséquence.
      console.error("Une erreur s'est produite:", error.error);
    } else {
      // Le backend a renvoyé un code de réponse d'échec.

      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);

      console.error(`L'Api renvoyé un code ${error.status}`);
    }

    // Renvoie un observable avec un message d'erreur destiné à l'utilisateur.
    return throwError(
      () =>
        new Error(
          "Quelque chose de terrible est arrivé; Veuillez réessayer plus tard."
        )
    );
  }

  addcar(val: any) {
    return this.http.post(this.getAuth.gethttps() + "addcar", val);
  }

  showModel(id: any) {
    let obj = {
      id,
    };
    return this.http.post(this.getAuth.gethttps() + "showModel", obj);
  }

  showType(id: any) {
    let obj = {
      id,
    };
    return this.http.post(this.getAuth.gethttps() + "showType", obj);
  }

  showEnergie(id: any) {
    let obj = {
      id,
    };
    return this.http.post(this.getAuth.gethttps() + "showEnergie", obj);
  }

  showBoitVit(id: any) {
    let obj = {
      id,
    };
    return this.http.post(this.getAuth.gethttps() + "showBoitVit", obj);
  }

  showNbplace(id: any) {
    let obj = {
      id,
    };
    return this.http.post(this.getAuth.gethttps() + "showNbplace", obj);
  }

  showNbporte(id: any) {
    let obj = {
      id,
    };
    return this.http.post(this.getAuth.gethttps() + "showNbporte", obj);
  }

  saveimage(val: any) {
    
    return this.http.post(this.getAuth.gethttps() + "saveimage", val);

  }

  @Cacheable()
  showMark() {

    return this.http.get(this.getAuth.gethttps() + "showMark");

  }

  @Cacheable()
  showAllModel() {
    return this.http.get(this.getAuth.gethttps() + "showAllModel");
  }

  storecaracteristique(val: any) {
    let obj = {
      val,
    };
    return this.http.post(
      this.getAuth.gethttps() + "storecaracteristique",
      obj
    );
  }

  getallvehicle(data: any) {
    return this.http.get(this.getAuth.gethttps() + "getallvehicle/" + data);
  }

  //affiche le total de vehicule par particuliers
  sommeVehiculeParticulier(data: any) {
    return this.http.get(
      this.getAuth.gethttps() + "sommeVehiculeParticulier/" + data
    );
  }

  getallvehicleAdmin() {
    return this.http.get(this.getAuth.gethttps() + "getallvehicleAdmin");
  }

  getallNbvehicleOn10Years() {
    return this.http.get(this.getAuth.gethttps() + "getallNbvehicleOn10Years");
  }

  @Cacheable()
  getallcar() {
    return this.http.get(this.getAuth.gethttps() + "getallcar");
  }

  @Cacheable()
  getallCardispo(): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(
      this.getAuth.gethttps() + "getallCardispo"
    );
  }

  @Cacheable()
  allVehicleBerline(): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(
      this.getAuth.gethttps() + "allVehicleBerline"
    );
  }

  @Cacheable()
  allVehicleMonospace(): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(
      this.getAuth.gethttps() + "allVehicleMonospace"
    );
  }

  @Cacheable()
  allVehicleUtilitaire(): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(
      this.getAuth.gethttps() + "allVehicleUtilitaire"
    );
  }

  @Cacheable()
  allVehicleSuv(): Observable<MenuItem[]> {
    return this.http.get<MenuItem[]>(
      this.getAuth.gethttps() + "allVehicleSuv"
    );
  }

  moyNoteCar(val: any) {
    return this.http.post(this.getAuth.gethttps() + "moyNoteCar", val);
  }

  suprimeCar(val: any) {
    return this.http.post(this.getAuth.gethttps() + "suprimeCar", val);
  }

  findCar(val: any) {
    return this.http.post(this.getAuth.gethttps() + "findCar", val);
  }
  updateCar(val: any) {
    return this.http.post(this.getAuth.gethttps() + "updateCar", val);
  }

  getvehicleConstat(val: any) {
    
    let obj = {
      idUser: val,
    };

    return this.http.post(this.getAuth.gethttps() + "getvehicleConstat", obj);
  }

  getvehicleSinisterLitige(val:any, key: string){
    let obj = {
      idUser: val,
      key: key
    };
    return this.http.post(this.getAuth.gethttps() + "getvehicleSinisterLitige", obj);
  }

  updateCarSuper(val: any) {
    return this.http.post(this.getAuth.gethttps() + "updateCarSuper", val);
  }
  /*
   *Cette methode permet de rechercher
   *les véhicules en fonction de certains paramètres
   */
  searchCar(itemSearch: any) {
   
    if(itemSearch.diffDate != null) {
      
      itemSearch.dateStart = this.datePipe.transform(itemSearch.dateStart, 'yyyy/MM/dd'),
      itemSearch.dateEnd = this.datePipe.transform(itemSearch.dateEnd, 'yyyy/MM/dd')

    }
    
    return this.http.post(this.getAuth.gethttps() + "searchCar", itemSearch).pipe(catchError(this.handleError));
  }

  /**
   * Cette methode renvoit tout les type de véhicules
   */
  // typeList() {
  //   return this.http.get(this.getAuth.gethttps() + "typeList").pipe(catchError(this.handleError));
  // }

  /**
   * Cette methode renvoit les informations du véhicule selectionné dans l'interface de recherche.
   *
   * @param car
   * @returns
   */
  showCar(car: any) {
    return this.http.post(this.getAuth.gethttps() + "showCarRenting", car).pipe(catchError(this.handleError));
  }
  
  @Cacheable()
  vehicleMap() {
    return this.http
      .get(this.getAuth.gethttps() + "vehicleMap")
      .pipe(catchError(this.handleError));
  }

  @Cacheable()
  showOneCar(id: any) {
    return this.http.get(this.getAuth.gethttps() + "showOneCar/"+id);
  }

  updateImageOfOneCar(files:Object)
  { 
    return this.http.post(this.getAuth.gethttps() + "updateImageCar", files).pipe(catchError(this.handleError));
  }

  changePriorityCar(priorityOfCar:Object)
  {
    return this.http.post(this.getAuth.gethttps()+"changePriority",priorityOfCar).pipe(catchError(this.handleError));
  }

  lockedRentingDateForVehicule(obj: Object) {

    return this.http.post(this.getAuth.gethttps()+"lockedRentingDateForVehicule", obj);
    
  }
}
